<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item ref="notificationCollapse">
        <div slot="header">
          <h3 class="text-left card-title font-normal">Notifications</h3>
        </div>
        <p>
          Where would you like email notifications to be sent and what status notifications would you like to receive?
        </p>
        <vs-row class="mt-4">
          <vs-col vs-w="12">
            <label for="emailAddress" class="w-full text-xm font-normal">Webhook URL</label>
            <vs-input
              size="large"
              v-model="webhookUrl"
              data-vv-validate-on="blur"
              data-vv-as="webhookUrl"
              v-validate="'url'"
              name="webhookUrl"
              id="webhookUrl"
              class="w-8/12"
              @blur="handleWebhookUrl"
            />
          </vs-col>
          <vs-col vs-w="12">
            <span class="text-danger text-sm" v-show="errors.has('webhookUrl')">{{ errors.first("webhookUrl") }}</span>
          </vs-col>
        </vs-row>

        <vs-row class="notific-cards">
          <vs-col class="spacing-radio w-auto">
            <div class="radio-card notification-radio my-5" @click="activateNotify(true)" :class="{ active: active_el == true }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">
                  <p class="text-xl">Request sender</p>
                </vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="defaultEmailNotification" vs-value="1" vs-name="notification"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <span class="radio-info">Send notifications to the person that sends the payment request only.</span>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
          <vs-col class="w-auto">
            <div class="radio-card notification-radio my-5" @click="activateNotify(false)" :class="{ active: active_el == false }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">
                  <p class="text-xl">Other</p>
                </vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="defaultEmailNotification" vs-value="0" vs-name="notification"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <span class="radio-info">Enter an email address to receive notifications for this template. Users can opt to also receive notifications directly.</span>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
        <vs-row vs-w="4" v-if="!defaultEmailNotification" class="mb-6">
          <vs-col vs-w="12">
            <label for="emailAddress" class="w-full text-xm font-normal">Email address <span class="mid-blue">*</span></label>
            <vs-input
              size="large"
              v-model="notification.emailAddress"
              data-vv-validate-on="blur"
              data-vv-as="email address"
              v-validate="this.defaultEmailNotification === false ? 'required' : ''"
              name="emailAddress"
              id="emailAddress"
              class="w-8/12"
            />
          </vs-col>
          <vs-col vs-w="12">
            <span class="text-danger text-sm" v-show="errors.has('emailAddress')">{{ errors.first("emailAddress") }}</span>
          </vs-col>
        </vs-row>

        <h5 class="notificaton-title mt-6 border-0">Transaction notifications</h5>
        <hr class="line-hr" />
        <vs-table :data="txNotification" class="mb-20 mt-6 notific-table" :disabled="notificationDisabled">
          <template slot="thead">
            <vs-th class="align-top"> Status </vs-th>
            <vs-th class="align-top"> Description </vs-th>
            <vs-th class="align-top">
              <div class="flex flex-col items-center">
                <label for="txNotifications_forEmail" class="w-full p-0">Enable all</label>
                <vs-switch id="txNotifications_forEmail" :color="switchColor" v-model="trCheckbox.forEmail" @click="check($event,trCheckbox.forEmail)" :disabled="notificationDisabled" />
              </div>
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.status">{{ tr.status }}</vs-td>
              <vs-td :data="tr.description">{{ tr.description }}</vs-td>
              <vs-td :data="tr.forEmail">
                <div class="flex justify-center">
                  <vs-checkbox v-model="tr.forEmail" :disabled="notificationDisabled"></vs-checkbox>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>

<script>
export default {
  props: ["notification", "prNotificationsCheckbox", "transactionNotificationsCheckbox", "isSubmitted", "webhookUrlData"],
  data() {
    return {
      active_el: true,
      defaultEmailNotification: true,
      notificationDisabled: false,
      switchColor: "#0F67F4",
      trCheckbox: {
        forEmail: "",
        forDashboard: "",
      },
      txNotification: [],
      webhookUrl: "",
    };
  },
  mounted() {
    this.txNotification = this.notification.txNotification;
    this.trCheckbox = this.transactionNotificationsCheckbox;
    this.active_el = this.notification.requestSenderEmail;
    this.defaultEmailNotification = this.notification.requestSenderEmail;
    this.webhookUrl = this.webhookUrlData;
  },
  watch: {
    notification: async function(val) {
      if (val.txNotification) {
        this.txNotification = val.txNotification;
      }
    },
    transactionNotificationsCheckbox: async function(val) {
      if (val) {
        this.trCheckbox = val;
      }
    },

    async isSubmitted(value) {
      this.startValidating();
    },
    isFormDirty(val) {
      if (val) {
        this.$emit("checkDirty",this.isFormDirty);
      }
    }
  },
  methods: {
    activateNotify(el) {
      this.active_el = el;
      this.defaultEmailNotification = el;
      this.$refs.notificationCollapse.maxHeight = "none !important";
      this.$emit("changeNotification", el);
    },
    check(e, _newVal) {
      _newVal = !_newVal;
      const info = e.target.id.split("_");
      const [type, field] = info;
      this.$emit("checkDirty", true);

      if (type.includes("tx")) {
        this.txNotification = this.checkUncheck(_newVal, this.txNotification, field);
        return;
      }

    },
    beChecked(value, field) {
      return value.every((el) => el[field]);
    },
    checkUncheck(ischecked, values, field) {
      return values.map((el) => {
        el[field] = ischecked;
        return el;
      });
    },
    async startValidating() {
      await this.$validator.validate();
    },
    handleWebhookUrl() {
      this.$emit("updateWebhookUrl", this.webhookUrl);
    }
  },
};
</script>
